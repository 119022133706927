import { DocumentType } from "./document";

export interface KeyValuePair {
  id: string;
  name: string;
  prompt: string;
  documentType: DocumentType;
  documentTypeId: string;
  code?: KvpCode;
  isDefault: boolean;
  problemTypeId?: string;
  type?: string;
  insightsDefinition?: string;
  dependencies?: {
    keyValuePairId: string;
    dependsOnValue: string;
  }[];
  _original?: KeyValuePair;
}

export enum KvpCode {
  // Insurance
  InsuranceType = 1,
  RenewalDate = 3,
  Premiums = 4,
  TotalCharges = 5,
  Deductibles = 6,
  Limits = 7,
  PaymentTerms = 8,
  Exclusions = 9,

  // NDA
  AgreementTerm = 102,
  DisclosingParties = 103,

  // Agreement
  AgreementTermAgreement = 202,
  LaunchDate = 203,
  GoverningLaw = 204,
  MarketingRightsClause = 205,
  MarketingRights = 206,
  TerminationRightsClause = 207,
  TerminationForConvenience = 208,
  TerminationForConveniencePeriod = 209,
  TerminationForMaterialBreach = 210,
  TerminationForMaterialBreachPeriod = 211,
  AutoRenewalClause = 212,
  AutoRenew = 213,
  AutoRenewalCancellationNotice = 214,
  SLAs = 215,
  BillingTerms = 216,
  PaymentTermsAgreement = 217,
  CancellationNotice = 218,
  PenaltiesFees = 219,
  ExpirationDate = 905,
  IndemnityClause = 221,
  LimitationOfLiabilityClause = 222,
  RenewalDateAgreement = 223,

  // Generic
  Type = 901,
  Parties = 902,
  EffectiveDate = 903,

  // SOW
  SOWTerm = 301,
  SOWEffectiveDate = 302,
  SOWExpirationDate = 303,

  // Nested KVPs
  ContractPriceAgreements = 228,
  ContractPriceAgreements2 = 229,
  ContractPriceSow = 304,
  ContractPriceSow2 = 305,
}
