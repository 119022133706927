import { isEmpty } from "lodash";

import API from "./api";
import { processSpecialFilters } from "./documents";

interface GetDashboardTabCountParams {
  documentTypeId?: string | null;
  parties?: string[];
  tags?: string[];
  search?: string;
  specialFilters?: Record<string, boolean>;
}

export interface GetDashboardTabCountResponse {
  activeCount: number;
  expiredCount: number;
}

export const getDashboardTabCount = async ({
  documentTypeId,
  parties,
  search,
  tags,
  specialFilters,
}: GetDashboardTabCountParams): Promise<GetDashboardTabCountResponse> => {
  try {
    const { data } = await API.get("/documents-extra/count", {
      params: {
        documentTypeId,
        parties:
          parties && !isEmpty(parties)
            ? parties?.map((party) => `"${party}"`).join(",")
            : undefined,
        tags:
          tags && !isEmpty(tags)
            ? tags?.map((tag) => `"${tag}"`).join(",")
            : undefined,
        search: search && !isEmpty(search) ? search : undefined,
        ...processSpecialFilters(specialFilters),
      },
    });

    return data;
  } catch (err) {
    throw new Error((err as Error)?.message || "Error getting dashboard data");
  }
};
