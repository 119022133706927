import { DocumentTag } from "@atlas-ui/types";

import { isEmpty } from "lodash";

import API from "./api";

export interface GetDocumentTagsParams {
  documentId?: string;
  documentTypeId?: string | null;
  excludeAutogenerated?: boolean;
  insights?: boolean;
  abortSignal?: AbortSignal;
}

export interface CreateDocumentTagParams {
  documentId: string;
  name: string;
  abortSignal?: AbortSignal;
}

export interface UpdateDocumentTagsParams {
  tags: (Partial<DocumentTag> & { id: string })[];
  abortSignal?: AbortSignal;
}

export const getDocumentTags = async ({
  documentId,
  documentTypeId,
  insights,
  excludeAutogenerated,
  abortSignal,
}: GetDocumentTagsParams) => {
  try {
    const { data } = await API.get(`/document-tags`, {
      params: {
        documentId,
        documentTypeId:
          documentTypeId && !isEmpty(documentTypeId)
            ? documentTypeId
            : undefined,
        insights,
        excludeAutogenerated: excludeAutogenerated ? "true" : undefined,
      },
      signal: abortSignal,
    });

    return data as DocumentTag[];
  } catch (error) {
    throw new Error((error as Error)?.message || "Error getting document tags");
  }
};

export const createDocumentTag = async ({
  documentId,
  name,
  abortSignal,
}: CreateDocumentTagParams) => {
  try {
    const { data } = await API.post(
      `/document-tags`,
      {
        documentId,
        name,
      },
      {
        signal: abortSignal,
      }
    );

    return data;
  } catch (error) {
    throw new Error((error as Error)?.message || "Error creating document tag");
  }
};

export const removeDocumentTag = async (id: string) => {
  try {
    await API.delete(`/document-tags/${id}`);
  } catch (error) {
    throw new Error((error as Error)?.message || "Error removing document tag");
  }
};
