import React, { FC, PropsWithChildren, useCallback, useMemo } from "react";
import { useQueryClient } from "react-query";

import { LimitType, User } from "@atlas-ui/types";

import { sumBy } from "lodash";

import {
  AuthenticationContext,
  AuthenticationContextType,
  ShownLimits,
} from "./authentication-context";
import { useDocsnapLimits } from "./use-docsnap-limits";
import { useDocsnapUser } from "./use-docsnap-user";

// Provides to all the application the user and organization information
export const AuthenticationProvider: FC<PropsWithChildren> = ({ children }) => {
  const queryClient = useQueryClient();

  const { data: user, isLoading: isUserLoading } = useDocsnapUser();

  const { data: limits, isLoading: areLimitsLoading } = useDocsnapLimits();

  const updateUser = useCallback((user: Partial<User>) => {
    if (!user) return;

    queryClient.setQueryData(["user"], (prevCachedData?: User) => {
      if (!prevCachedData) return user as User;

      return {
        ...prevCachedData,
        ...user,
      };
    });
  }, []);

  const getLimit = useCallback(
    (type: LimitType): ShownLimits => {
      if (!limits)
        return {
          limit: 0,
          used: 0,
          available: 0,
        };

      const { usage, tier } = limits;

      const tierLimit = tier?.limits?.find((limit) => limit.type === type);
      const usageForType = usage?.filter((usage) => usage.type === type) ?? [];
      const used = sumBy(usageForType, "value");

      return {
        limit: tierLimit?.value ?? 0,
        used,
        available: tierLimit?.value ? tierLimit.value - used : 0,
      };
    },
    [limits]
  );

  const value: AuthenticationContextType = useMemo(
    () => ({
      user: user ?? null,
      limits: limits ?? null,
      isLoading: isUserLoading || areLimitsLoading,
      getLimit,
      updateUser,
    }),
    [user, isUserLoading, areLimitsLoading, updateUser, getLimit]
  );

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};
