import { Insight, InsightsParams, PaginatedResponse } from "@atlas-ui/types";

import { isEmpty } from "lodash";

import API from "./api";

interface CreateInsightParams {
  documentTypeId: string;
  documents: { id: string }[];
  name: string;
}

export const getInsights = async (options: InsightsParams) => {
  try {
    const response = await API.get("/insights", {
      params: {
        ...options,
        parties:
          !options.parties || isEmpty(options.parties)
            ? undefined
            : options?.parties?.map((party) => `"${party}"`).join(","),
        tags:
          !options.tags || isEmpty(options.tags)
            ? undefined
            : options?.tags?.map((tag) => `"${tag}"`).join(","),
      },
    });
    return response.data as PaginatedResponse<Insight>;
  } catch (error) {
    throw new Error((error as Error)?.message || "Error getting comparisons");
  }
};

export const getInsightById = async (id: string) => {
  try {
    const response = await API.get(`/insights/${id}`);
    const data = response.data;
    return data as Insight;
  } catch (error) {
    throw new Error((error as Error)?.message || "Error getting comparison");
  }
};

export const updateInsight = async (id: string, insight: Partial<Insight>) => {
  try {
    const response = await API.patch(`/insights/${id}`, insight);
    return response.data as Insight;
  } catch (error) {
    throw new Error((error as Error)?.message || "Error updating comparison");
  }
};

export const createInsight = async (params: CreateInsightParams) => {
  try {
    const response = await API.post("/insights", params);
    return response.data as Insight;
  } catch (error) {
    throw new Error((error as Error)?.message || "Error creating comparison");
  }
};

export const deleteInsight = async (id: string) => {
  try {
    await API.delete(`/insights/${id}`);
  } catch (error) {
    throw new Error((error as Error)?.message || "Error deleting comparison");
  }
};
